import SVLink from '@pkgs/shared-client/components/SVLink';
import IconSearchSVG from '@pkgs/shared-client/img/icon-search-inlined.svg';
import colorHEXToHSV from '@pkgs/shared/helpers/colorHEXToHSV';
import { twMerge } from 'tailwind-merge';

const SVColorBalls = ({ colors, className }: { colors: string[]; className?: string }) => {
	return (
		<div className={twMerge('flex space-x-2', className)}>
			{colors.map((color) => (
				<SVLink
					key={color}
					className="duration-over-long border-separator group flex h-10 w-10 min-w-0 cursor-pointer items-center justify-center overflow-hidden rounded-[20px] border transition-all delay-[100ms] hover:flex-auto hover:delay-[0ms]"
					title={`Search by ${color}`}
					to={{
						pathname: '/search/',
						query: { q: color },
					}}
					style={{
						backgroundColor: color,
					}}
				>
					<IconSearchSVG
						className={twMerge(
							'duration-over-long mr-2 h-3 w-3 opacity-0 transition-opacity group-hover:opacity-100',
							(colorHEXToHSV(color)?.v || 0) >= 50 ? 'text-black' : 'text-white',
						)}
					/>

					<span
						className={twMerge(
							'duration-over-long text-sm font-medium opacity-0 transition-opacity group-hover:opacity-100',
							(colorHEXToHSV(color)?.v || 0) >= 50 ? 'text-black' : 'text-white',
						)}
					>
						{color}
					</span>
				</SVLink>
			))}
		</div>
	);
};

export default SVColorBalls;
