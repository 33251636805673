import { ItemFragmentFragment, type Item } from '@apps/www/src/__generated__/graphql';
import SVGridItemHoverOverlayContainer from '@apps/www/src/www/containers/SVGridItemHoverOverlayContainer';
import SVTrackedUpgradeButtonContainer from '@apps/www/src/www/containers/SVTrackedUpgradeButtonContainer';
import useAllowedFeatureState, {
	ALLOWED_STATES,
} from '@apps/www/src/www/hooks/useAllowedFeatureState';
import useAuthSettings from '@apps/www/src/www/hooks/useAuthSettings';
import useAuthSubscriptionIsExpired from '@apps/www/src/www/hooks/useAuthSubscriptionIsExpired';
import useGridPageRole from '@apps/www/src/www/hooks/useGridPageRole';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import { UIStateKeys, useSetUIState } from '@apps/www/src/www/hooks/useUIState';
import useUserAndBoardPageQuery from '@apps/www/src/www/hooks/useUserAndBoardPageQuery';
import { type RootState } from '@apps/www/src/www/reducers';
import { clearItemsSelection, toggleItemSelection } from '@apps/www/src/www/reducers/grid';
import { UPLOAD_DONE } from '@apps/www/src/www/reducers/gridUpload';
import SVA from '@pkgs/shared-client/components/SVA';
import SVButton, { SVButtonSIZES, SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import type {
	ItemPassthroughProps,
	LikeItem,
	Props as SVGridProps,
} from '@pkgs/shared-client/components/SVGrid';
import SVGrid from '@pkgs/shared-client/components/SVGrid';
import SVGridItem from '@pkgs/shared-client/components/SVGridItem';
import SVLink from '@pkgs/shared-client/components/SVLink';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import {
	EXPIRED_ITEM_LIMIT,
	FREE_ITEM_LIMIT,
	FREE_RELATED_ITEM_LIMIT,
	GridHoverOverlayUISize,
	UNLOGGED_ITEM_LIMIT,
} from '@pkgs/shared/constants';
import AllowedFeature from '@pkgs/shared/enums/AllowedFeature';
import BoardUserRole from '@pkgs/shared/enums/BoardUserRole';
import ItemsSortMethod from '@pkgs/shared/enums/ItemsSortMethod';
import UpgradeButtonSource from '@pkgs/shared/enums/UpgradeButtonSource';
import boardUserRoleHasBoardUserRolePrivileges from '@pkgs/shared/helpers/boardUserRoleHasBoardUserRolePrivileges';
import clsx from 'clsx';
import { NextRouter, useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUnmount } from 'react-use';
import SVGridSortingContainer from './SVGridSortingContainer';

const _BottomLogin = () => {
	return (
		<div className="flex justify-center py-24">
			<div className="max-w-xl flex-col justify-center space-y-6">
				<h3 className="type-subtitle font-semibold tracking-tight">
					Join to see more saves
				</h3>
				<div className="flex-center flex space-x-6">
					<SVButton
						use={SVButtonUSES.PRIMARY}
						Component={SVLink}
						to="/join/"
						title="Join"
					>
						Join
					</SVButton>
					<SVA Component={SVLink} to="/login/" title="Log in">
						Log in
					</SVA>
				</div>
			</div>
		</div>
	);
};

const _BottomUpsell = () => {
	return (
		<div
			className={clsx('my-20 flex w-full justify-center')}
			title="Want this image in better quality?"
		>
			<div className={clsx(' flex w-[765px] max-w-[95%] flex-col gap-y-6 px-6')}>
				<div className="flex flex-1 flex-col justify-center gap-y-1">
					<div className="type-subtitle text-center font-semibold text-white">
						Want to keep scrolling with new inspiration?
					</div>
					<div className="type-small text-center font-normal text-gray-400">
						Get access to Premium features like this and much more for less than $2 a
						week.
					</div>
				</div>
				<div className="flex justify-center">
					<SVTrackedUpgradeButtonContainer
						source={UpgradeButtonSource.END_OF_GRID_UPSELL}
						size={SVButtonSIZES.PAYMENT}
					>
						Upgrade
					</SVTrackedUpgradeButtonContainer>
				</div>
			</div>
		</div>
	);
};

type Props = Pick<
	SVGridProps,
	| 'columnsConfigID'
	| 'spacingConfigID'
	| 'sourceType'
	| 'items'
	| 'isLoading'
	| 'loadingSkeletonCount'
	| 'onPaginate'
	| 'isStatic'
	| 'onItemClick'
> & {
	sortMethod?: ValueOf<typeof ItemsSortMethod>;
};

function isItemFragment(item: LikeItem): item is ItemFragmentFragment {
	return '__typename' in item && item.__typename === 'Item' && !('upload' in item);
}

// Detached into a component so `renderHoverOverlay` can re-render when `item` changes and we can
// avoid having complicated logic inside `SVGridItem` to decide if re-rendering is needed.
function _Item({
	item,
	router,
	canSort,
	isSelected,
	sortingItemID,
	isSortLoading,
	role,
	sourceType,
	...props
}: {
	item: LikeItem;
	sourceType?: ValueOf<typeof SVGrid.SOURCE_TYPES>;
	router: NextRouter;
	canSort: boolean;
	isSelected: boolean;
	sortingItemID: Item['_id'] | null;
	isSortLoading?: boolean;
} & ItemPassthroughProps) {
	const renderHoverOverlay = useCallback(
		(props: { uiSize: GridHoverOverlayUISize; canMove: boolean }) => {
			return isItemFragment(item) ? (
				<SVGridItemHoverOverlayContainer
					{...props}
					item={item}
					isSortLoading={isSortLoading || false}
					role={role}
				/>
			) : null;
		},
		[item, isSortLoading, role],
	);

	return (
		<SVGridItem
			{...SVGridItem.itemToProps(item)}
			sourceType={sourceType}
			routerPathname={router.pathname}
			routerQuery={router.query}
			canSort={canSort}
			isSelected={isSelected}
			isSorting={Boolean(sortingItemID && sortingItemID === item._id)}
			renderHoverOverlay={renderHoverOverlay}
			role={role}
			{...props}
		/>
	);
}

const SVBaseGridContainer = ({
	columnsConfigID,
	items,
	isLoading,
	loadingSkeletonCount,
	onPaginate,
	sourceType,
	spacingConfigID,
	sortMethod,
	isStatic,
	onItemClick,
}: Props) => {
	// When grid is from related items, we strip out any editing capability or communication with the
	// global state of current page items
	const isRelatedItems = sourceType === SVGrid.SOURCE_TYPES.RELATED;

	const router = useRouter();
	const isLoggedIn = useIsLoggedIn();
	const hasAutoPlayGIF =
		useAllowedFeatureState(AllowedFeature.AUTO_PLAY_GIF) === ALLOWED_STATES.ALLOWED;
	const authSettings = useAuthSettings(['autoPlayGIFs']);
	const { user, board } = useUserAndBoardPageQuery();
	const setPageGridItems = useSetUIState(UIStateKeys.CURRENT_PAGE_GRID_ITEMS);
	const hasFullFeed = useAllowedFeatureState(AllowedFeature.FULL_FEED) === ALLOWED_STATES.ALLOWED;
	const dispatch = useDispatch();
	const hasExpiredSubscription = Boolean(useAuthSubscriptionIsExpired());

	// For related items, we treat the role as viewer only
	const originalPageRole = useGridPageRole();
	const role = isRelatedItems ? BoardUserRole.VIEWER : originalPageRole;

	const { isSorting, isSortLoading, isEditing, uploadingItems, selectedItemIDs, sortingItemID } =
		useSelector((state: RootState) => ({
			isSorting: state.grid.isSorting,
			isSortLoading: state.grid.isSortLoading,
			isEditing: state.grid.isEditing,
			uploadingItems: state.gridUpload.items,
			selectedItemIDs: state.grid.selectedItemIDs,
			sortingItemID: state.grid.sortingItemID,
		}));

	const handleItemClick = useEventCallback((itemID: Item['_id'], event: React.UIEvent) => {
		if (onItemClick) {
			onItemClick(itemID, event);
		}

		if (isEditing) {
			dispatch(toggleItemSelection(itemID));

			// Prevent default behavior when editing to avoid navigating to item detail page
			event.preventDefault();
			return false;
		}
	});

	// Needs to be a regular useCallback to change reference every time selectedItemIDs updates, thus re-rendering the grid
	const isItemSelected = useCallback(
		(itemID: Item['_id']) => {
			return selectedItemIDs?.includes(itemID);
		},
		[selectedItemIDs],
	);

	useEffect(() => {
		if (!isRelatedItems) {
			setPageGridItems(items || []);
		}
	}, [setPageGridItems, items, isRelatedItems]);

	useUnmount(() => {
		if (!isRelatedItems) {
			setPageGridItems([]);
			dispatch(clearItemsSelection());
		}
	});

	const autoPlayGIFs = Boolean(hasAutoPlayGIF && authSettings?.autoPlayGIFs);
	const itemsOrEmpty = items || [];
	const hasEditorPrivileges = boardUserRoleHasBoardUserRolePrivileges(role, BoardUserRole.EDITOR);

	let allItems = itemsOrEmpty;

	// Dedupe items based on item.asset._id. We shoulnd't have duplicates on
	// the grid, but sometimes the server returns duplicates and it needs to be
	// fixed there. This is just a temporary fail safe.
	allItems = allItems.filter(
		(item, index, self) => index === self.findIndex((t) => t.asset._id === item.asset._id),
	);

	if (hasEditorPrivileges && uploadingItems?.length) {
		allItems = [
			...uploadingItems.filter(
				(item) =>
					item.upload.status != UPLOAD_DONE &&
					(!board || item.upload.boardID === board._id),
			),
			...allItems,
		];
	}

	const canSort = hasEditorPrivileges && sortMethod === ItemsSortMethod.CUSTOM;

	// Anything but own user items or own board items is a limited grid source
	const isLimitedGridSource =
		sourceType === SVGrid.SOURCE_TYPES.FEED ||
		sourceType === SVGrid.SOURCE_TYPES.SEARCH ||
		sourceType === SVGrid.SOURCE_TYPES.POPULAR ||
		sourceType === SVGrid.SOURCE_TYPES.RELATED ||
		(!hasEditorPrivileges &&
			(sourceType === SVGrid.SOURCE_TYPES.USER || sourceType === SVGrid.SOURCE_TYPES.BOARD));

	const freeItemLimit = !isLoggedIn
		? UNLOGGED_ITEM_LIMIT
		: sourceType === SVGrid.SOURCE_TYPES.RELATED
		? FREE_RELATED_ITEM_LIMIT
		: hasExpiredSubscription
		? EXPIRED_ITEM_LIMIT
		: FREE_ITEM_LIMIT;

	const showBottomLogin =
		!isLoggedIn &&
		!isLoading &&
		items &&
		(items?.length >= freeItemLimit || sourceType === SVGrid.SOURCE_TYPES.RELATED);
		
	const showBottomUpsell =
		!showBottomLogin &&
		!hasFullFeed &&
		isLimitedGridSource &&
		items &&
		items?.length >= freeItemLimit;

	if (!hasFullFeed && isLimitedGridSource && allItems.length > freeItemLimit) {
		allItems = allItems.slice(0, freeItemLimit);
	}

	return (
		<div>
			<SVGrid
				autoPlayGIFs={autoPlayGIFs}
				columnsConfigID={columnsConfigID}
				isLoaded={!isLoading && Boolean(items)}
				// columnsConfigID can be undefined if viewportName is not ready yet (on server and first client render)
				isLoading={isLoading || !columnsConfigID}
				loadingSkeletonCount={loadingSkeletonCount}
				role={role}
				sourceType={sourceType}
				isSorting={isSorting}
				isSortLoading={isSortLoading}
				isStatic={isStatic}
				items={allItems}
				keyExtractor={(item) => item._id}
				onItemClick={handleItemClick}
				onPaginate={showBottomUpsell ? undefined : onPaginate} // Don't paginate if we're showing the upsell at the end
				spacingConfigID={spacingConfigID}
				renderItem={(item, props) => (
					<_Item
						item={item}
						router={router}
						canSort={canSort}
						isSelected={isItemSelected(item._id)}
						sortingItemID={sortingItemID}
						{...props}
					/>
				)}
			/>
			{canSort && user && (
				<SVGridSortingContainer
					items={itemsOrEmpty}
					board={board}
					user={user}
					sortMethod={sortMethod}
				/>
			)}
			{showBottomLogin ? <_BottomLogin /> : null}
			{showBottomUpsell ? <_BottomUpsell /> : null}
		</div>
	);
};

export default SVBaseGridContainer;
