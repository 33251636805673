import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type SuggestedUsersQueryQuery,
	type SuggestedUsersQueryQueryVariables,
} from '@apps/www/src/__generated__/graphql';

const SuggestedUsersQuery: TypedDocumentNode<
	SuggestedUsersQueryQuery,
	SuggestedUsersQueryQueryVariables
> = gql`
	query SuggestedUsersQuery {
		suggestedUsers {
			_id
			name
			username
			url
			avatarURL
			isPro
			canFollow
			isFollowing
			isFollowingBack
			items(limit: 1) {
				items {
					_id
					asset {
						_id
						image {
							thumbnail
						}
						colors {
							color
						}
					}
				}
			}
		}
	}
`;

export default SuggestedUsersQuery;
