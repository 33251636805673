import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type TopColorsQueryQuery,
	type TopColorsQueryQueryVariables,
} from '@apps/www/src/__generated__/graphql';

const TopColorsQuery: TypedDocumentNode<TopColorsQueryQuery, TopColorsQueryQueryVariables> = gql`
	query TopColorsQuery {
		topColors
	}
`;

export default TopColorsQuery;
