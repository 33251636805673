import formatCount from '@pkgs/shared-client/helpers/formatCount';
import IconCloseSVG from '@pkgs/shared-client/img/icon-close-inlined.svg';
import clsx from 'clsx';
import SVIconButton from './SVIconButton';

type Props = {
	itemsCount: number | null;
	onClose: () => void;
	actions: React.ReactNode;
	boardList: React.ReactNode;
};

const _Wrapper = ({ children, className }: { children: React.ReactNode; className?: string }) => (
	<div className={clsx('flex items-center space-x-4', className)}>{children}</div>
);

const SVGridEditBar = ({ itemsCount, onClose, actions, boardList }: Props) => {
	const hasItems = !!itemsCount;

	return (
		<div
			className="theme-dark type-small text-secondary -lg:w-[90%] -md:w-[95%] -sm:hidden backdrop-hide m-6 mx-auto mt-0 flex h-[92px] w-[70%] max-w-full items-center justify-between space-x-4 rounded-2xl bg-gray-950 bg-opacity-[85%] p-6 font-semibold backdrop-blur-lg transition-all duration-500 ease-in-out"
			data-testid="grid-edit-bar"
		>
			{!hasItems ? (
				<div>
					<div className="type-subnav text-gray-200">You are on selection mode.</div>
					<div className="text-sm text-gray-500">
						Click or drag to select multiple saves.
					</div>
				</div>
			) : (
				<_Wrapper className="-md:max-w-[35vw] -mdp:max-w-[25vw] max-w-[30vw]">
					<div className="-md:text-sm text-[16px] text-gray-300">
						{formatCount(itemsCount)} selected
					</div>
					<div className="flex max-w-full flex-grow items-start justify-start">
						{hasItems && boardList}
					</div>
				</_Wrapper>
			)}

			<_Wrapper>
				{!hasItems && <div className="text-sm text-gray-500">Press [esc] to close.</div>}
				{hasItems && actions}

				<SVIconButton onClick={onClose} src={IconCloseSVG} label="Close" keys="escape" />
			</_Wrapper>
		</div>
	);
};

export default SVGridEditBar;
