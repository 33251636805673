import clsx from 'clsx';

const COLORS = [
	[
		'#FFFFFF',
		'#E8E8E8',
		'#D1D1D1',
		'#B9B9B9',
		'#A2A2A2',
		'#8B8B8B',
		'#747474',
		'#5D5D5D',
		'#464646',
		'#2E2E2E',
		'#171717',
		'#000000',
	],
	[
		'#003448',
		'#031B53',
		'#0F053A',
		'#2D053B',
		'#3A0619',
		'#580601',
		'#571C00',
		'#543100',
		'#533B00',
		'#635D00',
		'#4C5303',
		'#243B0E',
	],
	[
		'#014A62',
		'#002D77',
		'#180C4E',
		'#430E55',
		'#511127',
		'#7D1201',
		'#762700',
		'#774700',
		'#745601',
		'#878204',
		'#6C720D',
		'#365218',
	],
	[
		'#006A8A',
		'#003FA2',
		'#290973',
		'#5E1578',
		'#74173C',
		'#AE1901',
		'#A73A00',
		'#A36500',
		'#A07701',
		'#BCB500',
		'#959E0C',
		'#4B7625',
	],
	[
		'#0087AD',
		'#0451CE',
		'#36198D',
		'#762099',
		'#94234E',
		'#DB2205',
		'#D24E00',
		'#CB7C00',
		'#CB9700',
		'#EBE405',
		'#BCC913',
		'#639730',
	],
	[
		'#009BD0',
		'#005FF4',
		'#4B20AE',
		'#9229B4',
		'#B32B5A',
		'#F73D14',
		'#F96600',
		'#F5A600',
		'#F4C000',
		'#F5F33F',
		'#D2E436',
		'#70B63B',
	],
	[
		'#00C2F3',
		'#3B82F5',
		'#5B2DE2',
		'#B935EC',
		'#DE3777',
		'#F65E4D',
		'#F88145',
		'#F6AE3d',
		'#F4C43C',
		'#F6EF63',
		'#DBE660',
		'#91CB5D',
	],
	[
		'#51CFF3',
		'#71A1F6',
		'#814BF6',
		'#CB56F6',
		'#E76F99',
		'#F4887B',
		'#F7A078',
		'#F6C173',
		'#F7D270',
		'#F8EF8F',
		'#E2E989',
		'#ABD687',
	],
	[
		'#8DDAF2',
		'#A2BFF8',
		'#A988F4',
		'#DB8DF4',
		'#EB9EBB',
		'#F6ADA7',
		'#F6BFA7',
		'#F8D3A2',
		'#F5DCA3',
		'#F4F3B2',
		'#E9EDB2',
		'#C5DFAF',
	],
	[
		'#C3E7F5',
		'#CCDBF8',
		'#D2C1F5',
		'#E5C3F6',
		'#F0CCD6',
		'#F7D4D2',
		'#F8DCD0',
		'#F6E4CC',
		'#F4E9CD',
		'#F5F4D6',
		'#EDF2D4',
		'#D7E6CF',
	],
];

// Removing unused variables
// const TILE_WIDTH = 100 / COLORS[0].length;
// const TILE_HEIGHT = 100 / COLORS.length;

type Props = {
	onColorClick: (color: string) => void;
	onClose?: (event: React.UIEvent) => void;
	className?: string;
};

const SVColorPickerOverlayContent = ({ onColorClick, onClose, className }: Props) => {
	return (
		<>
			{onClose && <div onClick={onClose} className="close -md:44 h-56 w-full text-white" />}
			<div
				className={clsx(
					'relative flex w-full flex-grow flex-wrap',
					className,
				)}
			>
				<div className="grid h-full w-full grid-cols-12 grid-rows-10">
					{COLORS.map((row, k) =>
						row.map((color, j) => (
							<button
								key={k + '-' + j}
								className="block hover:scale-110"
								style={{
									backgroundColor: color,
								}}
								onClick={() => {
									onColorClick(color);
								}}
							/>
						)),
					)}
				</div>
			</div>
		</>
	);
};

export default SVColorPickerOverlayContent;
