import { useQuery } from '@apollo/client';
import SVLoadingWrapper from '@apps/www/src/www/containers/SVLoadingWrapper';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import useNotificationsLimit from '@apps/www/src/www/hooks/useNotificationsLimit';
import NotificationsQuery from '@apps/www/src/www/queries/NotificationsQuery';
import { RootState } from '@apps/www/src/www/reducers';
import { closeNotifications } from '@apps/www/src/www/reducers/ui';
import SVCenter from '@pkgs/shared-client/components/SVCenter';
import SVFlexSpacer from '@pkgs/shared-client/components/SVFlexSpacer';
import SVIconButton from '@pkgs/shared-client/components/SVIconButton';
import SVNotifications from '@pkgs/shared-client/components/SVNotifications';
import SVRetractableBar from '@pkgs/shared-client/components/SVRetractableBar';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import IconCloseSVG from '@pkgs/shared-client/img/icon-close-inlined.svg';
import NotificationType from '@pkgs/shared/enums/NotificationType';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const _Content = () => {
	const dispatch = useDispatch();
	const limit = useNotificationsLimit();

	const { data, loading, error } = useQuery(NotificationsQuery, {
		variables: {
			limit,
		},
	});

	const handleClose = useEventCallback(() => {
		dispatch(closeNotifications());
	});

	return (
		<div className="z-index-notificationstype-subtitle border-l-separator bg-background -sm:w-screen relative flex h-full w-[400px] flex-col items-center overflow-y-auto border-l">
			<div className="flex w-full items-center p-8 pb-0">
				<h2 className="type-subnav font-semibold">Notifications</h2>
				<SVFlexSpacer />
				<SVIconButton
					onClick={handleClose}
					src={IconCloseSVG}
					label="Close"
					keys="escape"
				/>
			</div>
			<SVLoadingWrapper isLoading={loading}>
				{() => {
					if (error) {
						return <SVCenter>An error ocurred. Try again later</SVCenter>;
					}

					return (
						<SVNotifications>
							{!data || data.notifications.length === 0 ? (
								<>
									<SVCenter>No notifications yet</SVCenter>
								</>
							) : (
								data.notifications.map((item) => {
									switch (item.type) {
										case NotificationType.NEW_FOLLOWER:
											return (
												<SVNotifications.ItemNewFollower
													key={item._id}
													item={item}
												/>
											);
										case NotificationType.NEW_SAVE:
											return (
												<SVNotifications.ItemNewSave
													key={item._id}
													item={item}
												/>
											);
										case NotificationType.INVITE_COLLABORATOR:
											return (
												<SVNotifications.ItemInviteCollaborator
													key={item._id}
													item={item}
												/>
											);
										case NotificationType.INVITE_COLLABORATOR_ACCEPTED:
										case NotificationType.INVITE_COLLABORATOR_REJECTED:
											return (
												<SVNotifications.ItemInviteCollaboratorAnswer
													key={item._id}
													item={item}
												/>
											);
										case NotificationType.INVITE_TEAM_USER:
											return (
												<SVNotifications.ItemInviteTeamUser
													key={item._id}
													item={item}
												/>
											);
										case NotificationType.INVITE_TEAM_USER_ACCEPTED:
										case NotificationType.INVITE_TEAM_USER_REJECTED:
											return (
												<SVNotifications.ItemInviteTeamUserAnswer
													key={item._id}
													item={item}
												/>
											);
										case NotificationType.MENTION_IN_COMMENT:
										case NotificationType.NEW_COMMENT:
										case NotificationType.REPLY_TO_COMMENT:
											return (
												<SVNotifications.ItemComment
													key={item._id}
													item={item}
												/>
											);
									}

									return null;
								})
							)}
						</SVNotifications>
					);
				}}
			</SVLoadingWrapper>
		</div>
	);
};

const SVNotificationsContainer = React.memo(() => {
	const dispatch = useDispatch();
	const isOpen = useSelector((state: RootState) => state.ui.isNotificationsOpen);
	const isLoggedIn = useIsLoggedIn();

	const handleClose = useEventCallback(() => {
		dispatch(closeNotifications());
	});

	if (!isLoggedIn) {
		return null;
	}

	return (
		<>
			<SVRetractableBar
				position={SVRetractableBar.POSITIONS.RIGHT}
				isOpen={isOpen}
				onClose={handleClose}
				render={() => <_Content />}
			/>
		</>
	);
});

export default SVNotificationsContainer;
